<template>
  <div class="registration_view">
    <a-form>
      <div class="header-switching-buttons-container">
        <router-link to="/login">
          <a-button type="link" ghost class="header-switching__button">
            Вход
          </a-button>
        </router-link>
        <a-button type="link" class="header__switching__button__active">
          Регистрация
        </a-button>
      </div>
      <a-button-group class="registration__customer__switching__buttons">
        <div class="registration__customer__button__switch__active">
          <Button
            buttonClass="white-antd-button-height38"
            :style="'border-top-left-radius: 3px; border-top-right-radius: 0px 0px;border-bottom-right-radius: 0px 0px;border-bottom-left-radius:  3px 4px;'"
            >Заказчик</Button
          >
        </div>
        <router-link to="/registration_master">
          <Button
            style="border-top-right-radius: 3px; border-bottom-right-radius: 3px;'"
            class="registration__customer__button__switch"
            >Мастер</Button
          >
        </router-link>
      </a-button-group>
      <a-form-item name="name" :validateStatus="statusName" :help="helpName">
        <a-input
          :value="form.name"
          v-on:input="updateFormName($event)"
          size="large"
          placeholder="Имя"
        />
      </a-form-item>

      <a-form-item :validateStatus="statusSurname" :help="helpSurname">
        <a-input
          :value="form.surname"
          v-on:input="updateFormSurname($event)"
          size="large"
          placeholder="Фамилия"
        />
      </a-form-item>
      <a-form-item :validateStatus="statusMiddlename" :help="helpMiddlename">
        <a-input
          :value="form.middlename"
          v-on:input="updateFormMiddlename($event)"
          size="large"
          placeholder="Отчество"
        />
      </a-form-item>

      <a-form-item>
        <UploadFile
          name="Загрузить Фото"
          :files="form.files"
          @unloadFile="unloadFile"
        ></UploadFile>
      </a-form-item>

      <a-form-item :validateStatus="statusEmail" :help="helpEmail">
        <a-input
          :value="form.email"
          v-on:input="updateFormEmail($event)"
          size="large"
          placeholder="Почта"
        />
      </a-form-item>

      <a-form-item :validateStatus="statusPassword" :help="helpPassword">
        <a-input-password
          :value="form.password"
          v-on:input="updateFormPassword($event)"
          class="a-form-item-input"
          placeholder="Пароль"
          size="large"
        />
      </a-form-item>
      <a-form-item
        :validateStatus="statusPasswordRepeat"
        :help="helpPasswordRepeat"
      >
        <a-input-password
          :value="form.password_confirmation"
          v-on:input="updateFormPasswordConfirmation($event)"
          class="a-form-item-input"
          placeholder="Повторите Пароль"
          size="large"
        />
      </a-form-item>

      <a-form-item>
        <Button @click="submitForm()" buttonClass="red-antd-button">
          Зарегистрироваться
        </Button>
      </a-form-item>

      <a-form-item :validateStatus="statusAsign" :help="helpAsign">
        <div class="asign-user-agree">
          <Checkbox
            @change="updatePrivacy"
            className="checkbox-style"
          ></Checkbox>
          <div class="asign-user-agree-text">
            Я согласен с условиями
            <a class="link">Пользовательского соглашения</a>
            и
            <a class="link">Политикой обработки персональных данных</a>
          </div>
        </div>
      </a-form-item>
      <div class="button-entry-account">
        <div>Войти в аккаунт</div>
      </div>
    </a-form>
  </div>
</template>

<script>
import Checkbox from 'ant-design-vue/lib/checkbox'
import UploadFile from '@/components/common/UploadFile.vue'
import Button from '@/components/common/Button.vue'
import { login } from '@/helpers/authHelper'

import { deleteFile } from '@/helpers/dataHelpers'
// import * as EmailValidator from "email-validator";
import { validateEmail } from '@/helpers/validation'

export default {
  components: {
    Checkbox,
    UploadFile,
    Button
  },
  data () {
    return {
      statusName: 'success',
      helpName: '',
      statusSurname: 'success',
      helpSurname: '',
      statusMiddlename: 'success',
      helpMiddlename: '',
      statusEmail: 'success',
      helpEmail: '',
      statusPassword: 'success',
      helpPassword: '',
      statusPasswordRepeat: 'success',
      helpPasswordRepeat: '',
      statusAsign: 'success',
      helpAsign: '',
      headers: {
        authorization: 'authorization-text'
      },
      form: {
        name: '',
        surname: '',
        middlename: '',
        email: '',
        password: '',
        password_confirmation: '',
        privacy: false,
        image: ''
      }
    }
  },
  methods: {
    unloadFile (file) {
      if (this.form.image) {
        deleteFile(this.form.image)
      }
      this.form.image = file
    },
    submitForm () {
      if (this.form.name === '') {
        this.statusName = 'error'
        this.helpName = 'Пустое поле'
      }
      if (this.form.surname === '') {
        this.statusSurname = 'error'
        this.helpSurname = 'Пустое поле'
      }
      if (this.form.middlename === '') {
        this.statusMiddlename = 'error'
        this.helpMiddlename = 'Пустое поле'
      }
      if (this.form.email === '') {
        this.statusEmail = 'error'
        this.helpEmail = 'Пустое поле'
      }
      if (!validateEmail(this.form.email)) {
        this.statusEmail = 'error'
        this.helpEmail = 'Некорректная почта'
      }
      if (this.form.password.length < 5) {
        this.statusPassword = 'error'
        this.helpPassword = 'Пароль не может быть меньше 5 символов'
      }
      if (this.form.password === '') {
        this.statusPassword = 'error'
        this.helpPassword = 'Пустое поле'
      }

      if (this.form.password !== this.form.password_confirmation) {
        this.statusPasswordRepeat = 'error'
        this.helpPasswordRepeat = 'Пароли не совпадают'
      }
      if (this.form.privacy === false) {
        this.statusAsign = 'error'
        this.helpAsign = 'Подтвердите согласие'
      }

      if (
        this.statusName === 'success' &&
        this.statusSurname === 'success' &&
        this.statusMiddlename === 'success' &&
        this.statusEmail === 'success' &&
        this.statusPassword === 'success' &&
        this.statusPasswordRepeat === 'success' &&
        this.statusAsign === 'success'
      ) {
        this.sendForm()
      }
    },
    updateFormName (e) {
      this.form.name = e.target.value
      this.statusName = 'success'
      this.helpName = ''
    },
    updateFormSurname (e) {
      this.form.surname = e.target.value
      this.statusSurname = 'success'
      this.helpSurname = ''
    },
    updateFormMiddlename (e) {
      this.form.middlename = e.target.value
      this.statusMiddlename = 'success'
      this.helpMiddlename = ''
    },
    updateFormEmail (e) {
      this.form.email = e.target.value
      this.statusEmail = 'success'
      this.helpEmail = ''
    },
    updateFormPassword (e) {
      this.form.password = e.target.value
      this.statusPassword = 'success'
      this.helpPassword = ''
    },
    updateFormPasswordConfirmation (e) {
      this.form.password_confirmation = e.target.value
      this.statusPasswordRepeat = 'success'
      this.helpPasswordRepeat = ''
    },
    updatePrivacy (e) {
      this.form.privacy = e.target.checked
      this.statusAsign = 'success'
      this.helpAsign = ''
    },
    sendForm () {
      this.$http.post(process.env.VUE_APP_SERVER_URL + '/users/customer', this.form)
        .then((resp) => {
          const data = resp.data
          if (data.success && data.data.token) {
            login({
              image: data.data.image,
              name: data.data.name,
              uuid: data.data.uuid,
              token: data.data.token,
              userType: data.data.roles.name
            })
            this.$router.push('/main')
          } else {
            this.$notification.open({
              message: 'Ошибка регистрации',
              description: data.message
            })
          }
        })
          .catch((error) => {
            let description = ''
            if (error.response && error.response.message) {
              description = error.response.message
            } else if (error.response && error.response.data && error.response.data.message) {
               description = error.response.data.message
            }
            if (error && error.response && error.response.data && error.response.data.data) {
              Object.keys(error.response.data.data || {}).forEach((key) => {
                    console.log(error.response.data.data)
                    description += error.response.data.data[key][0]
                  }
              )
            }
            this.$notification.open({
              message: 'Сетевая ошибка входа в систему',
              description: description
            })
          })
    }
  }
}
</script>

<style lang="scss">
@import "../../assets/themes/layout.css";

.ant-input-affix-wrapper .ant-input:not(:first-child) {
  padding-left: 40px;
}
.left__button {
  border-radius: none;
}

.header-switching-buttons-container {
  width: 100%;
  /* border-bottom: 1px solid #f0f0f0; */
  margin-bottom: 29px;
}
.header__switching__button {
  color: black;
  &__active {
    border-bottom: 2px solid #b12021;
    color: #b12021;
    border-radius: 0px;
  }
}

.registration__customer {
  &__button__switch {
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: black;
    border: 1px solid #d9d9d9;
    border-left: 0px;
    padding: 5px 16px;
    border-radius: 0px;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;

    &:hover {
      color: #1890ff;
      border: 1px solid #1890ff;
    }
    &__active {
      border: 1px solid #b12021;
      border-top-left-radius: 4px;
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
      border-bottom-left-radius: 3px;
    }
  }
  &__switching__buttons {
    margin-bottom: 19px;
  }
}

.send-file-button-content-text {
  margin-left: 20px;
}

.registration-button {
  color: white;
  height: 40px;
  background-color: #b12021;
  width: 100%;
}

.button-entry-account {
  color: #b12021;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.a-form-item-input {
  height: 40px;
}

.a-form-item-select {
  height: 40px;
}

.asign-user-agree {
  display: flex;
  flex-direction: row;
}

.asign-user-agree-text {
  margin-left: 8px;
  line-height: 24px;
}

.link {
  color: black;
  text-decoration: underline;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #b12021;
  border-color: #b12021;
}

.ant-select-selection-placeholder {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
}
.ant-btn.ant-btn-link.ant-btn-background-ghost {
  color: black;
}

/* .ant-form-item-explain.ant-form-item-explain-error {
  color: #b12021;
} */
</style>
